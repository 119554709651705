import { Map } from 'immutable';
import Cookie from 'universal-cookie';
import invariant from 'invariant';
import { createSimpleSelector } from '@dbh/reselect-extra';
import { isServerSideRendering } from '@dbh/environment';
import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

const REDUX_AND_SAGA_KEY="UniversalCookie";const COOKIES_LOADED_IN_SSR="@dbh/universal-cookie/COOKIES_LOADED_IN_SSR";const COOKIES_REMOVED_AFTER_SSR_FINISHED="@dbh/universal-cookie/COOKIES_REMOVED_AFTER_SSR_FINISHED";

const initialState=Map({// Leave this `undefined`, so we can detect `COOKIES_LOADED_IN_SSR` not being
// dispatched or not being correctly dispatched in `makeSelectSsrCookies`, and
// throw. Otherwise we get nasty bugs in the HTML from server side rendering,
// such as `SVG` `URLs` pointing to the wrong hostname.
cookies:void 0,isGDPRComplianceModeDisabled:!1}),universalCookieReducer=function(a,b){void 0===a&&(a=initialState);const{type:c}=b;switch(c){case COOKIES_LOADED_IN_SSR:{const{payload:{cookies:c,isGDPRComplianceModeDisabled:d}}=b;return a.merge({cookies:Map(c),isGDPRComplianceModeDisabled:d})}case COOKIES_REMOVED_AFTER_SSR_FINISHED:// This package is not supposed to be used in the browser; first, because
// the latest cookies are readily available there, no need to select them.
// Second, because the cookies in the `redux` store would be outdated,
// and using them instead of the updated values from the browser, would
// cause bugs.
return initialState;default:return a}};var universalCookieReducer$1 = universalCookieReducer;

const isSSR=isServerSideRendering(),throwInTheBrowser=a=>{invariant(isSSR,"(`"+a+"`) Expected to be in server side rendering. You should not call this function in the browser, use `useCookie` or freely import `Cookie` from `universal-cookie`; it will load the most updated cookies directly and automatically, from the browser.");};var throwInTheBrowser$1 = throwInTheBrowser;

const selectStateSlice=a=>a?a[REDUX_AND_SAGA_KEY]:void 0;var selectStateSlice$1 = selectStateSlice;

const fnName="makeSelectSsrCookies",makeSelectSsrCookies=()=>(throwInTheBrowser$1(fnName),createSimpleSelector(selectStateSlice$1,["cookies"],a=>{const b=null==a?void 0:a.toObject();return invariant(!!b,"(`makeSelectSsrCookies`) Expected cookies to be put in the reducer at the start of server side rendering, "),new Cookie(b)}));/**
 * Returns a selector that returns an instance of the `Cookie` class, with
 * cookies from `express.js` loaded in it. To only be used in server side
 * rendering.
 * @return {Cookie} .
 */var makeSelectSsrCookies$1 = makeSelectSsrCookies;

/**
 * Returns `true` if the `GDPR` compliance mode is disabled (we use a
 * runtime switch). It should only be disabled for temporary tests agreed with
 * the company, otherwise the company can face big legal issues and fines.
 * @return {boolean} .
 */const makeSelectIsGDPRComplianceModeDisabled=()=>createSimpleSelector(selectStateSlice$1,["isGDPRComplianceModeDisabled"]);var makeSelectIsGDPRComplianceModeDisabled$1 = makeSelectIsGDPRComplianceModeDisabled;

/**
 * Dispatched when cookies are loaded in the store, in server side rendering.
 * We load them in the store because in at least one selector, that is also
 * executed on the server, we need to get a value from the cookies.
 * @param {Object} options .
 * @param {Object} options.cookiesObject .
 * @param {boolean?} options.isGDPRComplianceModeDisabled .
 * @return {Object} .
 */const cookiesLoadedInSsr=withConformsTo("cookiesLoadedInSsr",["options",PropTypes.exact({cookiesObject:PropTypes.object.isRequired,isGDPRComplianceModeDisabled:PropTypes.bool}).isRequired],a=>{let{cookiesObject:b,isGDPRComplianceModeDisabled:c}=a;return throwInTheBrowser$1("cookiesLoadedInSsr"),{type:COOKIES_LOADED_IN_SSR,payload:{cookies:b,isGDPRComplianceModeDisabled:c}}});/**
 * Dispatched when server side rendering is finished, to remove the cookies from
 * the store. We don't want to increase the size of the store, and the response
 * to the browser, by leaving them in place. It's not needed, cookies can be
 * easily accessed in the browser.
 * @return {Object} .
 */const cookiesRemovedAfterSsrFinished=()=>(throwInTheBrowser$1("cookiesRemovedAfterSsrFinished"),{type:COOKIES_REMOVED_AFTER_SSR_FINISHED});

export { COOKIES_LOADED_IN_SSR, COOKIES_REMOVED_AFTER_SSR_FINISHED, REDUX_AND_SAGA_KEY, cookiesLoadedInSsr, cookiesRemovedAfterSsrFinished, makeSelectIsGDPRComplianceModeDisabled$1 as makeSelectIsGDPRComplianceModeDisabled, makeSelectSsrCookies$1 as makeSelectSsrCookies, universalCookieReducer$1 as universalCookieReducer };
